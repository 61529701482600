<template>
  <div class="shopDetail-container">
    <el-drawer class="shopDetail-drawer" title="店铺详情" :visible.sync="drawer" :append-to-body="true" direction="rtl" :before-close="handleClose" >
      <div v-loading="loading" element-loading-text="数据较多，拼命加载中..."  class="drawer-container">
        <div class="drawer-left">
          <div class="left-top">
            <el-form :model="editRuleForm" :rules="editRules" ref="editRuleForm" class="demo-ruleForm">
              <div class="detail-title">
                <div class="tit-info">
                    <div class="shopLogo-container">
                      <img v-if="shopInfo.shopLogo" :src="shopInfo.shopLogo" alt="">
                      <i v-else class="el-icon-user common-iconImg-MR"></i>
                    </div>
                    <div class="shopName-custName">
                      <span>{{ shopInfo.shopName || "--" }}</span>
                      <span>{{ shopInfo.customerName || "--" }}</span>
                    </div>
                </div>
              </div>
              <div class="detail-content">
                <div class="DisableEdit"> 所属平台: {{(shopInfo.platformName) || "--"}} </div>
                <div class="DisableEdit"> 所属类目: {{(shopInfo.categoryName) || "--"}} </div>
                <div class="DisableEdit width330"> 协议客服工作时长: {{`${shopInfo.agreeStartTime || '00:00:00'}~${shopInfo.agreeEndTime || '00:00:00'} (${shopInfo.serviceWorkTime}小时)`}} </div>
                <div class="DisableEdit"> 协议子账号数: {{(shopInfo.agreeSubNum) || "0"}} 个 </div>
                <div class="DisableEdit"> 服务内容: {{(shopInfo.serviceContentName) || "--"}} </div>
                <div class="DisableEdit"> 服务时间: {{`${shopInfo.startTime || '--'}~${shopInfo.endTime || '--'} (${shopInfo.shopServiceDay}天)`}}</div>
                <div class="DisableEdit"> 派单金额: ￥{{(shopInfo.price) || "0"}} </div>
              </div>
            </el-form>
          </div>
          <!-- <div class="upload-container">
            <div class="upload-top">
              <div class="upload-title">
                附件
              </div>
              <div class="tip">
                上传图片及文件，大小不超过50MB
                <div class="uploadBtn-container">上传文档<input @input="(e) => { uploadFile(e) }" class="uploadBtn-input" type="file">
                </div>
              </div>
            </div>
            <div v-if="fileList && fileList.length" class="fileList-container">
              <div v-for="(item, index) in fileList" :key="index" class="fileItem">
                <div class="fileItem-name">
                  <common-picture :fileUrl="item.url" :fileType="Config.fileType.imgType.includes(item.url.split('.')[item.url.split('.').length - 1]) ? 'imgText' : 'file'" :fileName="item.name"></common-picture>
                </div>
                <div class="fileItem-btn-Container">
                  <div class="fileItemBtn" @click="() => {download(item.url)}">下载</div>
                  <el-popconfirm placement="top" title="确定删除吗？" @confirm="deleteFile(item)">
                    <el-button type="text" size="small" slot="reference">删除</el-button>
                  </el-popconfirm>
                </div>
              </div>
            </div>
            <div v-if="!fileList || !fileList.length" class="fileList-container">
              <div class="fileItem">
                暂无合同
              </div>
            </div>
          </div> -->
          <el-tabs class="tabsDetail" v-model="activeTab">
            <el-tab-pane label="订单信息" name="1">
              <div class="create-company-conatiner">
                <table>
                  <tr class="table-title"><td>订单编号</td><td>订单名称</td><td>业务类型</td><td>备注</td></tr>
                  <tr><td @click="() => { openRoute(`/assign-order/receive-list?tabName=first&disOrderCode=${shopInfo.disOrderCode}&disOrderNo=${shopInfo.disOrderNo}&from=shopDetail`) }" style="color:#1890ff;cursor: pointer">{{shopInfo.disOrderCode || '--'}}</td><td>{{shopInfo.disOrderName || '--'}}</td><td>{{shopInfo.typeName || ''}}</td><td>{{shopInfo.remark || '--'}}</td></tr>
                </table>
              </div>
            </el-tab-pane>
            <el-tab-pane label="基本信息" name="2">
                <div class="detail-content">
                    <div class="DisableEdit">通知人: <span class="common-clr-0a6">{{(shopInfo && shopInfo.noticeName) || "--"}}</span></div>
                    <div class="DisableEdit">通知人所属部门: <span class="common-clr-0a6">{{(shopInfo && shopInfo.noticeDepartName) || "--"}}</span></div>
                    <div class="DisableEdit">创建人: <span class="common-clr-0a6">{{(shopInfo && shopInfo.createName) || "--"}}</span></div>
                    <div class="DisableEdit">创建时间: <span class="common-clr-0a6">{{(shopInfo && shopInfo.createTime) || "--"}}</span></div>
                </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="drawer-right">
          <el-tabs style="height: 100%;display: flex;flex-direction: column;" v-model="activeName">
            <el-tab-pane label="工作记录" name="appeal">
              <div>
                <textarea class="common-textarea-log"  @paste="handlePaste" v-model="recordContent" placeholder="工作小计" rows="6"></textarea>
                <!-- <el-input :autosize="{ minRows: 6, maxRows: 6 }" type="textarea" v-model="recordContent" placeholder="工作小计"></el-input> -->
                <div>
                  <div class="fileItem" v-for="(item, index) in filesList" :key="index">
                    <div><common-picture :fileUrl="item.fileUrl" :fileType="Config.fileType.imgType.includes(item.fileUrl.split('.')[item.fileUrl.split('.').length - 1]) ? 'imgText' : 'file'" :fileName="item.fileName"></common-picture></div>
                    <div class="common-blue-btn" @click="() => deleteFile(item.fileId)">删除</div>
                  </div>
                </div>
                <div class="appeal-commit">
                  <div>
                    <div class="addFileBtn">
                      <img style="margin-right: 8px" src="../../../assets/images/add_file_icon.svg" />添加附件
                      <input class="addFileInput" :multiple="false" @input="chooseFile" type="file" />
                    </div>
                    <div style="font-size: 12px; color: #ccc"> 上传文件及图片，大小不超过50M </div>
                  </div>
                  <el-button @click="() => {addWorkLog()}" class="common-screen-btn" type="primary" >发布</el-button>
                </div>
              </div>
              <common-work-log :followRecordList="followRecordList"></common-work-log>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { shopDetail, orderWorkLog, addWorkLog } from "../../../service/assignOrder.js";
import { upload } from "../../../service/upload.js";
import { selectCategory, selectPlatform } from "../../../service/common.js"
import { tableColumn, DateTransform, decimalNull, Config } from "../../../utils/index.js";
import CommonWorkLog from "../../../components/common/commonWorkLog.vue"
import CommonPicture from "../../../components/common/commonPicture.vue"
export default {
  props: ['drawer'],
  components: { 
    CommonWorkLog,
     CommonPicture
  },
  data() {
    return {
      Config,
      tableColumn,
      DateTransform,
      tableRow: {}, // 表格数据
      shopInfo: {},
      loading: false,
      fileList: [],
      orderTable: [],
      paginationOrder: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100],
      },
      orderParams: {
        pageNum: 1,
        pageSize: 10
      },
      serTable: [],
      paginationSer: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100],
      },
      serParams: {
        pageNum: 1,
        pageSize: 10
      },
      followRecordList: [], // 工作日志
      filesList: [], // 工作记录文件
      isShowInput: '',
      editRuleForm: {
        platId: [],
        startTime: "",
        endTime: "",
        contactName: '',
        contactPhone: '',
        busineseUserIds: [],
        warnTime: '',
        beforeDay: '',
        shopName: '',
        categoryId: '',
        platformId: ''
      },
      editRules: { // 校验规则
        agreeSubNum: [{ validator: decimalNull, trigger: "change" }],
      },
      editParams: {
        filedName: "",
        filedValue: "",
      },
      activeName: "appeal", // tabs
      activeTab: "1", // tabs
      recordContent: "", // 跟进记录内容
      platList: [], // 所属平台
      categoryList: [], //类目下拉
      categoryParams: {
          pageNum: 1,
          pageSize: 10,
          name: ''
      },
    };
  },
  created() {
    this.selectPlatform();
    this.selectCategory();
  },
  methods: {
    handlePaste(event) {
      const items = (event.clipboardData || window.clipboardData).items;
      let file = null;

      if (!items || items.length === 0) {
        this.$message.error("当前浏览器不支持本地");
        return;
      }
      // 搜索剪切板items
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf("image") !== -1) {
          file = items[i].getAsFile();
          break;
        }
      }
      if (!file) {
        // this.$message.error("粘贴内容非图片");
        return;
      }
      this.chooseFile(file, 'copy')
    },
    openRoute (url) { // 打开新标签，跳转店铺详情
      window.open(url)
    },
    selectPage(type) { // 下拉分页
        if (type == 'up') {
            this.categoryParams.pageNum--
        } else {
            this.categoryParams.pageNum++
        }
        this.selectCategory()
    },
    remoteCategory(val) { // 所属类目下拉-筛选
        this.categoryParams.pageNum = 1
        this.categoryParams.name = val
        this.selectCategory()
    },
    async selectCategory() { // 类目下拉
        let categoryParams = {...this.categoryParams}
        let { data } = await selectCategory(categoryParams)
        this.categoryList = data.list
    },
    async selectPlatform() { // 平台下拉
        let { data } = await selectPlatform({pageNum: -1})
        this.platList = data
    },
    openNewWin(url) {
      window.open(url)
    },
    async chooseFile(e, type) { // 添加工作日志文件
      let file
      if (type != 'copy') {
        file = e.target.files[0];
      } else {
        file = e
      }
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (file.size / 1024 > 1024 * 50) {
        if (type != 'copy') { e.target.value = "" }
        return this.$message.error('文件大小不能超过50M')
      }
      if (!Config.fileType.PWEIType.includes(format)) {
        if (type != 'copy') { e.target.value = "" }
        return this.$message.error(`只能上传 ${Config.fileMessage.PWEIType} 格式的文件`)
      }
      this.loading = true
      let resData = (await upload({ file: file })).data;
      this.loading = false
      let fileItem = {
        fileType: "",
        fileName: file.name,
        fileId: resData.id,
        fileUrl: resData.filename,
      };
      if ( format == "bmp" || format == "jpg" || format == "png" || format == "gif" || format == "jpeg" ) {
        fileItem.fileType = "img";
      }
      this.filesList.push(fileItem);
      if (type != 'copy') { e.target.value = "" }
    },
    async shopDetail (params) { // 店铺详情
        this.loading = true
        let { data } = await shopDetail(params)
        this.loading = false
        this.shopInfo = data
        if (data.fileUrl && data.fileName) {
            this.fileList = [{url: data.fileUrl, name: data.fileName, id: data.fileId}]
        } else {
            this.fileList = []
        }
    },
    getDataInfo(row) { // 父组件传默认值
      this.tableRow = row
      this.shopDetail({shopId: row.shopId, disOrderNo: row.disOrderNo})
      this.orderWorkLog(row.disOrderNo)
    },
    deleteFile (id) { // 删除工作日志文件
      let filesList = [...this.filesList]
      this.filesList = filesList.filter((item)=>{
        return item.fileId != id
      })
    },
    download(url) {
      // 下载
      window.location.href = url
    },
    async addWorkLog() { // 工作记录发布按钮
      let filesList = [...this.filesList]
      let filesUrl = []
      let filesId = []
      let filesName = []
      filesList.map((item) => {
        filesUrl.push(item.fileUrl)
        filesId.push(item.fileId)
        filesName.push(item.fileName)
      })
      let params = {
        disOrderNo: this.tableRow.disOrderNo,
        content: this.recordContent,
        fileIds: filesId.join(','),
        filedName: filesName.join(','),
        filedUrl: filesUrl.join(','),
      };
      this.loading = true
      let { code } = await addWorkLog(params)
      this.loading = false
      if (code == 200) {
        this.orderWorkLog(this.tableRow.disOrderNo)
        this.filesList = []
        this.recordContent = "";
        this.$message.success("添加成功");
      }
    },
    async orderWorkLog(id) { // 工作日志
      let { data } = await orderWorkLog({realtionId: id})
      if (data && data.length) {
        data.map((item) => {
          if (item.value) {
            item.value = JSON.parse(item.value)
          } else {
            item.value = []
          }
        })
      }
      this.followRecordList = [...data]
    },
    handleClose(done) {
      this.$emit("handleClose", done);
    },
  },
};
</script>
<style lang="less" scoped>
.fileItem {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}
.shopDetail-drawer {
  .create-company-conatiner {
    table {
      border: 1px solid #afafaf;
      box-sizing: border-box;
      .table-title {
        td {
          font-weight: 700;
        }
      }
      td {
        border: 1px solid #afafaf;
        padding: 12px 16px;
      }
    }
  }
  /deep/ .el-drawer__body {
    padding: 0;
  }
  /deep/.el-tabs__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0 8px 0 16px;
  }
  /deep/.el-tab-pane {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  /deep/ .el-drawer__open .el-drawer.rtl {
    width: 1656px !important;
  }
  .drawer-container {
    display: flex;
    height: 100%;
    box-sizing: border-box;
    .fileNames {
      color: #1890ff;
      margin-top: 8px;
      word-break: break-all;
      cursor: pointer;
    }
    .drawer-left {
      padding: 32px 24px;
      text-align: left;
      width: 1150px;
      height: 100%;
      border-right: 1px solid #e8e8e8;
      box-sizing: border-box;
      overflow-x: hidden;
      .left-top {
        display: flex;
        justify-content: space-between;
        .demo-ruleForm {
          width: 1020px;
        }
      }
      .tabsDetail {
        margin-top: 24px;
      }
      .upload-container {
        margin-top: 24px;
        .fileList-container {
          border: 1px solid #EBEEF5;
          margin-top: 12px;
          border-radius: 4px;
          .fileItem {
            display: flex;
            justify-content: space-between;
            padding: 0 24px;
            border-bottom: 1px solid #EBEEF5;
            height: 74px;
            line-height: 74px;
            align-items: center;
            .fileItem-btn-Container {
              display: flex;
              .fileItemBtn {
                margin-right: 16px;
                color: #1890ff;
                cursor: pointer;
              }
            }
          }
        }
        .upload-top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #999999;
          .tip {
            font-size: 12px;
          }
          .upload-title {
            font-size: 18px;
            color: #101010;
          }
          .uploadBtn-container {
            width: 100px;
            height: 32px;
            line-height: 32px;
            border: 1px solid #1890FF;
            color: #1890FF;
            font-size: 14px;
            background: #fff;
            text-align: center;
            border-radius: 4px;
            display: inline-block;
            margin-left: 16px;
            position: relative;
            .uploadBtn-input {
              width: 100px;
              height: 32px;
              cursor: pointer;
              position: absolute;
              left: 0;
              top: 0;
              opacity: 0;
              font-size: 0;
            }
          }
        }
      }
    }
    .drawer-right {
      width: 500px;
      height: 100%;
      overflow-y: auto;
      box-sizing: border-box;
      .appeal-commit {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
        align-items: center;
        padding-bottom: 10px;
        .addFileBtn {
          height: 20px;
          color: #1890ff;
          font-size: 14px;
          display: flex;
          align-items: center;
          position: relative;
          .addFileInput {
            position: absolute;
            left: 0;
            top: 0;
            width: 120px;
            height: 20px;
            opacity: 0;
            cursor: pointer;
            font-size: 0;
          }
        }
      }
      
    }
    .detail-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 16px 12px;
      .tit-info {
        display: flex;
        align-items: center;
        .shopLogo-container {
          width: 64px;
          height: 64px;
          border-radius: 50%;
          overflow: hidden;
          cursor: pointer;
          img {
            width: 100%;
          }
          .common-iconImg-MR {
            width: 64px;
            height: 64px;
            font-size: 50px;
            line-height: 64px;
          }
        }
        .shopName-custName {
          display: inline-block;
          margin: 0 16px;
          span {
            display: block;
            &:nth-of-type(1) {
              color: #333;
              font-size: 18px;
            }
            &:nth-of-type(2) {
              color: #999;
              font-size: 14px;
            }
          }
        }
      }
    }
    .detail-content {
      .DisableEdit {
        display: inline-block;
        width: 280px;
        // height: 21px;
        padding: 8px 12px;
        font-size: 14px;
        color: #999;
        border-radius: 4px;
      }
      .width330 {
        width: 330px !important;
      }
    }
  }
  /deep/ .el-drawer__open .el-drawer.rtl {
    width: 1656px !important;
  }
}
</style>
